// base url for API request
/* eslint-disable import/prefer-default-export */

// export const baseUrl = 'http://localhost:3000/api/review';
export const baseUrl = 'https://review.neoito.com/api/review';
// 'http://ec2-65-2-131-153.ap-south-1.compute.amazonaws.com/api/review';
// 'https://616c-45-115-91-134.ngrok.io/api/review';
// 'http://ec52-45-115-91-134.ngrok.io/api/review';
// 'https://9c63-45-115-91-134.ngrok.io/api/review';

export const accessToken = 'AQVWH8BJOWHUAlbZp8q875i97OwZmoLaDvJfgGNP8SyC-WiIPSfal-mfT8mXfioN-OWEb3tO2HlQQGA7YxpivQeHCUTtcKGrxkutyf8FACsekma2GUuyOvLf-bqvA9q5aQdH85Rf8PwIesWs7CCDa4wFSWo0Ku8OGsMbf4WKakNykfv-JL-4dfgUFU9juqnoqLWOmIvtlmRW3SGk7CchNeuWhnGZF_ufTvjK5trHqRWgqaeGsw3x82f2d_C3ZQMD54QyZGD0KwrqNigo3IPZ46m4_xL-65lo3LrfPBBMIMA9LXqdel1GyQj6EW397Ra_AaXaOwF6bnof8qqUl_341DemMbUSZA';

// export const profileDatas = {"localizedLastName":"SIMON","profilePicture":
// {"displayImage":"urn:li:digitalmediaAsset:C4D03AQESUHK6naoP_A"},"firstName":{"localized":
// {"en_US":"ASHA"},"preferredLocale":{"country":"US","language":"en"}},"lastName":
// {"localized":{"en_US":"SIMON"},"preferredLocale":{"country":"US","language":"en"}},
// "id":"QufHLyMo8H","localizedFirstName":"ASHA"}
