const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
      // [::1] is the IPv6 localhost address.
      || window.location.hostname === '[::1]'
      // 127.0.0.0/8 are considered localhost for IPv4.
      || window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
      ),
);

export const rootUrl = isLocalhost ? 'http://localhost:3001/' : 'https://review.neoito.com/';
// 'http://ec2-65-2-131-153.ap-south-1.compute.amazonaws.com/';

export const REACT_APP_CLIENT_ID = '86zyp2iahc8grn'; // '86mk5zrkxaipik';
export const REACT_APP_CLIENT_SECRET = '5nU8eKEtX2YlydDi'; // '93E9pEkx9JUo89Sb';
export const REACT_APP_REDIRECT_URI = isLocalhost ? 'http://localhost:3001/linkedIn' : 'https://review.neoito.com/linkedIn';
// 'http://ec2-65-2-131-153.ap-south-1.compute.amazonaws.com/linkedIn';
