import React, { useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Modal from 'react-modal';
import clutchImg from '../../assets/images/png/ClutchImg.png';
import googleImg from '../../assets/images/png/GoogleImg.png';
import googleFirmImg from '../../assets/images/png/GoogleFirmImg.png';
import facebookbImg from '../../assets/images/png/FbImg.png';
import thumbImg from '../../assets/images/png/VideoThumbImg.png';
import rectLeft from '../../assets/images/png/RectangleLeft.png';
import rectRight from '../../assets/images/png/RectangleRight.png';
import playBtnImg from '../../assets/images/svg/play-btn.svg';
import neoitoVideo from '../../assets/videos/Neoito.mp4';
import 'react-accessible-accordion/dist/fancy-example.css';
import './styles.scss';
import { useHistory } from 'react-router';
import PostPreview from './PostPreview';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


export const GoogleReviewSection = () => (
    <div className="flex pt-8 pb-10 my-12 google-section relative">
        {/* <div className="bg-circle-shape-right"></div> */}
        <img src={rectRight} alt="bg__rect_img" className="right-circle-bg-image" />
        <div className="w-1/2 mx-4 px-6 text-left flex justify-end">
        <div className="lg:w-3/4 md:w-4/5 text-left description-part">
                <h4 className="mb-2 pb-4 social-media-section-title">Google - <span>$50</span></h4>
                <p>
                A Google review is quick and easy and will only take around 2 minutes to complete.
                </p>
                <ul className="procedure-list">
                    <li>Search for 'NeoITO' in Google and locate the details section on the right side of the search results. </li>
                    <li>Scroll down within this section to find a review tab that allows you to write a public review about your experience working with us. 
                        It'd be great if you could please leave a <b>5-star review </b>while you're at it. </li>
                    <li>Note that you'll need to be signed in to your google account to post a review. </li>
                </ul>
                <button type="button" className="google-review-btn" onClick={() => window.open("https://g.page/neoito-utah?share", "_blank")}>Review Us on Google</button>
            </div>
        </div>
        <div className="w-1/2 flex mx-4 px-6 justify-start items-center">
            <img src={googleImg} alt="google_img" />
        </div>
    </div>
);


const EndorseResponsiveSection = (props: any) => {
    const { clientId } = props;
    const history = useHistory();
    const [isOpen, setOpen] = useState(false);
    const goToPostReviewPage = () => {
        // history.push(`/post-review/${clientId}`);
    }

    return (
        <>
        {/* <div className="bg-circle-shape-left" />
        <div className="bg-circle-shape-right"/> */}
        <Accordion allowZeroExpanded className="endorse-accordion-section">
        {/* <img src={rectLeft} alt="bg__rect_img" className="left-circle-bg-image" /> */}
        {/* <img src={rectRight} alt="bg__rect_img" className="right-circle-bg-image" /> */}
            <AccordionItem aria-expanded={true}>
                <AccordionItemHeading>
                    <AccordionItemButton><div className='accordion-header-title'>Clutch  - <span>$200</span></div>
                        {/* <img src={arrowDown} alt="ArrowDown"/> */}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="w-full mx-auto px-2 pt-10">
                        <img src={clutchImg} alt="clutch_img" />
                    </div>
                    <div className="w-full text-left mx-auto mt-4 mb-2 px-2 py-6 clutch-section">
                        <p>
                        Clutch is one of the leading B2B research, ratings, and review sites that identifies leading IT and marketing service providers and software. 
                        A Clutch review takes an average of only 15 minutes. 
                        </p>
                        <ul className="procedure-list">
                        <li>Choose the “Review over the phone” option and you can schedule a call with a Clutch representative at your convenience.
                          It will hardly take 15 minutes of your time.</li>
                        <li>Once that's done, please don't forget to leave a <b> 5-star review</b> to help others know about your experience working with us. </li>
                        <li>Your review will be published by the Clutch team within a couple of days.</li>
                        <li>Alternative: You can also opt to post a review directly by logging into your LinkedIn account.
                            But it’s not recommended as it might take longer.</li>
                      </ul>
                        <button type="button" className="clutch-review-btn" onClick={() => window.open("https://clutch.co/review/91155", "_blank")}>Give Us a review on Clutch</button>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton><div className='accordion-header-title'>Google - <span>$50</span></div></AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="w-full mx-auto px-2 pt-10">
                        <img src={googleImg} alt="google_img" width="100%" height="auto"/>
                    </div>
                    <div className="w-full text-left mx-auto mt-4 mb-2 px-2 py-6 google-review-section">
                    <p>A Google review is quick and easy and will only take around 2 minutes to complete.</p>
                    <ul className="procedure-list">
                      <li>Search for 'NeoITO' in Google and locate the details section on the right side of the search results. </li>
                      <li>Scroll down within this section to find a review tab that allows you to write a public review about your experience working with us. 
                        It'd be great if you could please leave a <b>5-star review </b>while you're at it. </li>
                      <li>Note that you'll need to be signed in to your google account to post a review. </li>
                    </ul>
                        <button type="button" className="google-review-btn" onClick={() => window.open("https://g.page/neoito-utah?share")}>Review Us on Google</button>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                {/* <div className="bg-circle-shape-left" /> */}
                <AccordionItemHeading>
                    <AccordionItemButton><div className='accordion-header-title'>GoodFirms - <span>$150</span></div></AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="w-full mx-auto px-2 pt-10">
                        <img src={googleFirmImg} alt="google_firm_img" width="100%" height="auto"/>
                    </div>
                    <div className="w-full text-left mx-auto mt-4 mb-2 px-2 py-6 goodfirms-section">
                        <p>
                            GoodFirms is a research and review platform helping buyers to choose the best application development, design and
                            marketing services. The review will take a maximum of 10 minutes to complete
                        </p>
                        <ul className="procedure-list">
                        <li>We'd love to see <b>5-star ratings</b> from you on all the four options available before you click on the text review tab.</li>
                        <li>Mention the title of your project, and describe your experience working with us. Then choose the project category, and once you're done with the review, click on “post review”.
                        </li>
                      </ul>
                        <button type="button" className="google-firms-signup-btn" onClick={() => window.open("https://www.goodfirms.co/reviewers/signup")}>Sign Up to GoodFirms</button>
                        <button type="button" className="google-firms-btn" onClick={() => window.open("https://www.goodfirms.co/reviews/add/9020/neoito")}>NeoITO on GoodFirms</button>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton><div className='accordion-header-title'>Facebook - <span>$50</span></div></AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="w-full mx-auto px-2 pt-10">
                        <img src={facebookbImg} alt="facebook_img" width="100%" height="auto"/>
                    </div>
                    <div className="w-full text-left mx-auto mt-4 mb-2 px-2 py-6">
                    <p>
                      Leaving a review on Facebook is the easiest of them all and it takes less than 5 minutes to complete. Here's how:</p>
                        <ul className="procedure-list">
                            <li>Sign in to your Facebook account</li>
                            <li>Click on the 'review' tab and please leave us a <b>5-star review</b></li>
                        </ul>
                        <button type="button" className="neoito-facebook-btn" onClick={() => window.open("https://www.facebook.com/neoito/reviews/?ref=page_internal")}>NeoITO on Facebook</button>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton><div className='accordion-header-title'>Video Testimonial - <span>$300</span></div></AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="w-full mx-auto px-2 pt-10 testimonial-video-section">
                        {/* <button id="playbtn" className="active" onClick={handlePlayButton}></button> */}
                        <div className="relative">
                        <img src={thumbImg} alt="thumbnail" />
                        <button id="playbtn" onClick={() => setOpen(true)}>
                          <img src={playBtnImg} alt="PlayBtn" />
                        </button>
                        <Modal
                          isOpen={isOpen}
                          // onAfterOpen={afterOpenModal}
                          onRequestClose={() => setOpen(!isOpen)}
                          style={customStyles}
                          contentLabel="Example Modal"
                          className="video-modal"
                          overlayClassName="video-modal-overlay"
                        >
                          <div><button className="close-btn" onClick={() => setOpen(!isOpen)}>&times;</button></div>
                          <video id="testimonial-video" autoPlay controls preload="metadata">
                            <source src={neoitoVideo} type="video/mp4" />
                          </video>
                        </Modal>
                        {/* <video id="testimonial-video" poster={thumbImg} controls src={neoitoVideo} preload="metadata">
                            <source src={neoitoVideo} type="video/mp4" />
                        </video> */}
                        </div>
                         {/* <div id="testimonial-video">
                           <iframe src='https://www.youtube.com/embed/1X3480PRhZ4?rel=0&modestbranding=1&autohide=1'
                              width="560" height="349" 
                              frameBorder='0'
                              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                              allowFullScreen
                              title='video'
                           />
                        </div> */}
                    </div>
                    <div className="w-full text-left mx-auto mt-4 mb-2 px-2 py-6">
                    <p>
                      Video testimonials add a high level of credibility and are also one of the most pleasant ways to help everyone know about your company and how we helped.
                      </p>
                        <p>Here’s a simple framework you can follow:</p>
                        <p>Video framework:</p>
                        <ul className="procedure-list">
                            <li>10-30s: Business Problem you were trying to solve</li>
                            <li>10s: Your introduction and company name</li>
                            <li>15-30s: About your company and your goals</li>
                            <li>15-30s: The biggest challenge you were facing before you found us</li>
                            <li>20-30s: How has NeoITO helped you reach your goals–financial, cultural, even personal goals?</li>
                            <li>10-15s: Why would you recommend us?</li>
                        </ul>
                    </div>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                {/* <div className="bg-circle-shape-left"></div> */}
                <AccordionItemHeading>
                    <AccordionItemButton><div className='accordion-header-title'>Social Media Posts - <span>$250</span></div></AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div className="w-full mx-auto px-2 pt-10 social-media-review-part">
                        {/* <img src={socialMediaImg} alt="social_media_img" width="100%" height="auto"/> */}
                        <PostPreview />
                    </div>
                    <div className="w-full text-left mx-auto mt-4 mb-2 px-2 py-6 social-media-section">
                        <p>
                            The power of social media is more than we can imagine and posts from you can do wonders for us.
                            Now, to make it an easy and stress-free experience, you can use our own social media tool below:
                        </p>
                        <ul className="procedure-list">
                            <li>Give us a shoutout on <span>LinkedIn </span> and <span>Twitter</span> by tagging<span> NeoITO </span>and <span>Faiz</span>.</li>
                            <li>Please use these tags so that we can get better visibility:
                              <p>#startups #entrepreneurship #founders #neoito #irecommendneoito #innovation</p>
                            </li>
                            <li>To make it easier, we've made post templates for you to directly copy and paste on each social media channel.
                        All you have to do is to make sure <span>Faiz</span> and <span>NeoITO</span> are tagged.</li>
                        </ul>
                        {/* <button type="button" className="social-media-review-btn" onClick={goToPostReviewPage}>Get Started Social Media Review Tool</button> */}
                    </div>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        </>
    );
}

export default EndorseResponsiveSection;
