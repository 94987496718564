import React, { useEffect, useRef, useState } from 'react';
import logo from '../../assets/images/svg/neoito-logo.svg';
import headerImg from '../../assets/images/png/HeaderImg(1).png';
import giftVoucher from '../../assets/images/png/gift(1).png';
import giftVoucherRect from '../../assets/images/png/gift-rect.png';
import rectLeft from '../../assets/images/png/RectangleLeft.png';
import rectRight from '../../assets/images/png/RectangleRight.png';
import googleFirmImg from '../../assets/images/png/GoogleFirmImg.png';
import facebookbImg from '../../assets/images/png/FbImg.png';
import thumbImg from '../../assets/images/png/VideoThumbImg.png';
import socialMediaImg from '../../assets/images/png/SocialMediaImg.png';
import neoitoVideo from '../../assets/videos/Neoito.mp4';
import playBtnImg from '../../assets/images/svg/play-btn.svg';
import EndorseResponsiveSection, { GoogleReviewSection } from './EndorseElements';
import Footer from './Footer';
import { useMediaQuery } from 'react-responsive';
import clutchImg from '../../assets/images/png/ClutchImg.png';
import { useHistory } from 'react-router-dom';
import { getClient } from '../Review/Apis/ReviewApis';
import { PreLoader } from '../Review/PreLoader';
import PostPreview from './PostPreview';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const LandingPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });
  const history = useHistory();
  const [clientId, setClientId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [clientDetails, setClientDetails] = useState({
    clientName: "",
    icon: "",
    projectName: ""
  })
  const endroseSectionRef = useRef<HTMLDivElement>(null);
  const pathName = history.location.pathname.split('/');

  const executeScroll = () => {
    endroseSectionRef.current && endroseSectionRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const goToPostReviewPage = () => {
    // history.push(`/post-review/${clientId}`);
  }


  // const getClientSettings = () => {
  //   const clientId = pathName[pathName.length - 1];
  //   let req = {
  //     id: clientId
  //   }
  //   console.log("req", req);
  //   getClientReviewData(req)
  //     .then((response) => {
  //       console.log("res", response)
  //       const payload = { ...response.templates[0], ...response.client };
  //       console.log('payload', payload);
  //     })
  // }

  const getClientDetails = async () => {
    const clientId = pathName[pathName.length - 1];
    setClientId(clientId);
    getClient({ id: clientId }).then((data) => {
      if (data) {
        setClientDetails(data);
        console.log(data);
        setIsLoading(false);
        // setTemplate(data);
        // setFinalTemplate(data);
        // data.forEach((template: any) => {
        //   options.push({ value: template.platformName, label: template.displayName },)
        // });
      }

    });
  }

  useEffect(() => {
    getClientDetails();
  }, []);

  return (
    <div className="w-full h-full flex flex-col thanks-page overflow-y-auto">
      <PreLoader loading={isLoading} />
      {!isLoading && (<>
        <section className="flex pl-4 pr-4 pt-5 w-full endorse-page-header">
          <div className="header-bg flex flex-col">
          <div className="w-full header-part-1 p-5">
            <div className="flex justify-between items-center mb-4 logo-section">
              <img src={logo} alt="Logo" />
              {isSmallScreen ? (<button type="button" className="endrose-now-btn mt-0" onClick={executeScroll}>Endorse Now </button>) : <></>}
            </div>
            <div className="flex flex-wrap title-container">
              <div className="md:w-1/2 p-10 h-full flex flex-col justify-center">
                <div className="lg:ml-20 header-title-wrapper">
                  <h3 className="header-title">
                    <span>Thank You.</span> {" "}{clientDetails.clientName}
                    <br />
                    for trusting NeoITO!
                  </h3>
                  <p>
                    Our goal is to help awesome people like you on their journey to success and
                    your <span>5-star endorsement </span> will have a massive impact
                    in helping us grow faster and stronger.
                  </p>
                  {!isSmallScreen ? (<button type="button" className="endrose-now-btn" onClick={executeScroll}>Endorse Now </button>) : <></>
                  }
                </div>
              </div>
              <div className="md:w-1/2 flex justify-end header-img">
                <img src={headerImg} alt="Header pic" />
              </div>
            </div>
          </div>
          <div className="heaader-part-2 w-full px-5 flex items-center">
            <div className="w-1/2 flex pl-20 pr-10 ml-8 items-center gift-voucher-part">
              {/* <div className=""> */}
              {/* <div className="voucher-stick"></div> */}
              <figure className="relative max-w-2xl p-8 md:p-0">
                <img src={!isSmallScreen ? giftVoucher : giftVoucherRect} alt="GiftVoucher" className="mx-2 gift-voucher-img w-full" />
                <p className="tag-img-name">Get Your <br /><span>$1000</span><br />Gift</p>
              </figure>
              <p className="w-3/4 ml-4 pl-10 pr-12 md:pl-8 md:pr-6 md:w-4/5 text-justify">
                {/* Your endorsements are crucial to our growth and we are very grateful for your support.
              To show our appreciation, we want to give you a <span>$2000</span> discount on your next invoice once you’ve completed all the endorsements. */}

                Your endorsements are crucial to our growth and we are very grateful for your support.
                To show our appreciation, we want to give you a discount on your next invoice for each endorsement and another  <span>$100</span> after completing all of them.
              </p>
              {/* </div> */}
            </div>
          </div>
          </div>
        </section>
        <main className="w-full flex-grow">
          <section className="relative py-5 w-full endrose-section text-center" ref={endroseSectionRef}>
            <h3>How can you Endorse?</h3>

            {isTabletOrMobile ? <EndorseResponsiveSection clientId={clientId} /> :
              (
                <div>
                  <div className="flex pt-6 pb-10 mt-6 mb-12 relative clutch-section">
                    {/* <div className="bg-circle-shape-left" /> */}
                    <img src={rectLeft} alt="bg__rect_img" className="left-circle-bg-image" />
                    <div className="w-1/2 flex mx-4 px-6 justify-end items-center clutch-section-img">
                      <img src={clutchImg} alt="clutch_img" />
                    </div>
                    <div className="w-1/2 mx-4 px-6 text-left">
                      <h4 className="mb-2 social-media-section-title">Clutch - <span>$200</span></h4>
                      <div className="lg:w-3/4 md:w-4/5 text-left description-part">
                        <p>
                          Clutch is one of the leading B2B research, ratings, and review sites that identifies leading IT and marketing service providers and software.
                          A Clutch review takes an average of only 15 minutes.
                        </p>
                        <ul className="procedure-list">
                          <li>Choose the “Review over the phone” option and you can schedule a call with a Clutch representative at your convenience.
                            It will hardly take 15 minutes of your time.</li>
                          <li>Once that's done, please don't forget to leave a <b>5-star review</b> to help others know about your experience working with us. </li>
                          <li>Your review will be published by the Clutch team within a couple of days.</li>
                          <li>Alternative: You can also opt to post a review directly by logging into your LinkedIn account.
                            But it’s not recommended as it might take longer.</li>
                        </ul>
                        <button type="button" className="clutch-review-btn" onClick={() => window.open("https://clutch.co/review/91155", "_blank")}>Give Us a Review on Clutch</button>
                      </div>
                    </div>
                  </div>

                  <GoogleReviewSection />

                  <div className="grid grid-cols-2 pt-8 pb-10 my-12 relative google-firm-section">
                    {/* <div className="bg-circle-shape-left"></div> */}
                    <img src={rectLeft} alt="bg__rect_img" className="left-circle-bg-image" />
                    <div className="flex mx-4 px-6 justify-end items-center">
                      <img src={googleFirmImg} alt="google_firm_img" />
                    </div>
                    <div className="mx-4 px-6 text-left flex justify-start">
                    <div className="lg:w-3/4 md:w-4/5 text-left description-part">
                        <h4 className="mb-2 pb-4 social-media-section-title">GoodFirms - <span>$150</span></h4>
                        <p>
                          GoodFirms is a research and review platform helping buyers to choose the best application development, design and
                          marketing services. The review will take a maximum of 10 minutes to complete.
                        </p>
                        <ul className="procedure-list">
                          <li>We'd love to see <b>5-star ratings </b>from you on all the four options available before you click on the text review tab.</li>
                          <li>Mention the title of your project, and describe your experience working with us. Then choose the project category, and once you're done with the review, click on “post review”.
                          </li>
                        </ul>
                        <button type="button" className="google-firms-signup-btn" onClick={() => window.open("https://www.goodfirms.co/reviewers/signup", "_blank")}>Sign Up to GoodFirms</button>
                        <button type="button" className="google-firms-btn" onClick={() => window.open("https://www.goodfirms.co/reviews/add/9020/neoito", "_blank")}>NeoITO on GoodFirms</button>
                      </div>
                    </div>
                  </div>


                  <div className="grid grid-cols-2 pt-20 pb-10 mt-32 mb- 12 relative facebook-section">
                    {/* <div className="bg-circle-shape-right"></div> */}
                    <img src={rectRight} alt="bg__rect_img" className="right-circle-bg-image" />
                    <div className="mx-4 px-6 text-left flex justify-end">
                    <div className="lg:w-3/4 md:w-4/5 text-left description-part">
                        <h4 className="mb-2 pb-4 social-media-section-title">Facebook - <span>$50</span></h4>
                        <p>
                          Leaving a review on Facebook is the easiest of them all and it takes less than 5 minutes to complete. Here's how:
                        </p>
                        <ul className="procedure-list">
                          <li>Sign in to your Facebook account</li>
                          <li>Click on the 'review' tab and please leave us a <b>5-star review</b></li>
                        </ul>
                        <button type="button" className="neoito-facebook-btn" onClick={() => window.open("https://www.facebook.com/neoito/reviews/?ref=page_internal", "_blank")}>NeoITO on Facebook</button>
                      </div>
                    </div>
                    <div className="flex mx-4 px-6 justify-start items-center">
                      <img src={facebookbImg} alt="facebook_img" />
                    </div>

                  </div>

                  <div className="grid grid-cols-2 pt-8 pb-10 my-12 relative testimonial-section">
                    {/* <div className="bg-circle-shape-right"></div> */}
                    <img src={rectLeft} alt="bg__rect_img" className="left-circle-bg-image" />
                    <div className="flex mx-4 px-6 justify-end testimonial-video-section items-center">
                      <div className="relative">
                        {/* <video id="testimonial-video" ref={videoRef} poster={thumbImg} preload="metadata">
                    <source src={neoitoVideo} type="video/mp4" />
                    </video> */}
                        <img src={thumbImg} alt="thumbnail" />
                        <button id="playbtn" onClick={() => setOpen(true)}>
                          <img src={playBtnImg} alt="PlayBtn" />
                        </button>
                        <Modal
                          isOpen={isOpen}
                          // onAfterOpen={afterOpenModal}
                          onRequestClose={() => setOpen(!isOpen)}
                          style={customStyles}
                          contentLabel="Example Modal"
                          className="video-modal"
                          overlayClassName="video-modal-overlay"
                        >
                          <div><button className="close-btn" onClick={() => setOpen(!isOpen)}>&times;</button></div>
                          <video id="testimonial-video" autoPlay controls preload="metadata">
                            <source src={neoitoVideo} type="video/mp4" />
                          </video>
                        </Modal>
                      </div>
                      {/* <div id="testimonial-video"> */}
                      {/* <iframe src='https://www.youtube.com/embed/1X3480PRhZ4?controls=0&showInfo=0'
                        width="560" height="349" 
                        frameBorder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        title='video'
                      /> */}
                      {/* <iframe 
                       width="560" 
                       height="349" 
                       src="https://www.youtube.com/embed/1X3480PRhZ4?modestbranding=1&showinfo=0"
                       title="YouTube video player" 
                       frameBorder="0" 
                       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                       allowFullScreen /> */}
                      {/* </div> */}
                    </div>
                    <div className="flex mx-4 px-6 text-left flex justify-start">
                    <div className="lg:w-3/4 md:w-4/5 text-left description-part">
                        <h4 className="mb-2 pb-4 social-media-section-title">Video Testimonial - <span>$300</span></h4>
                        <p>
                          Video testimonials add a high level of credibility and are also one of the most pleasant ways to help everyone know about your company and how we helped.
                        </p>
                        <p>Here’s a simple framework you can follow:</p>
                        <p>Video framework:</p>
                        <ul className="procedure-list">
                          <li>10-30s: Business Problem you were trying to solve</li>
                          <li>10s: Your introduction and company name</li>
                          <li>15-30s: About your company and your goals</li>
                          <li>15-30s: The biggest challenge you were facing before you found us</li>
                          <li>20-30s: How has NeoITO helped you reach your goals–financial, cultural, even personal goals?</li>
                          <li>10-15s: Why would you recommend us?</li>
                        </ul>
                        <p>You can refer to this cool video on how you can shoot great videos by yourself.
                          If you prefer, we can schedule a call to help guide you through the process online.</p>
                        <p>Please don’t worry about editing the video. You can send us everything you’ve recorded and we’ll take care of the edits.</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }


          </section>
          {!isTabletOrMobile && (<section className="relative w-full text-center social-media-review-section grid grid-cols-2">
            <div className="pt-8 pb-10 my-12 relative social-media-section">
              {/* <div className="bg-circle-shape-left"></div> */}
              <div className="flex mx-4 px-6 justify-end items-center relative">
              <div className="lg:w-3/4 md:w-4/5 text-left description-part">
                  <h4 className="mb-2 pb-4 social-media-section-title">Social Media Posts - <span>$250</span></h4>
                  <p>
                    The power of social media is more than we can imagine and posts from you can do wonders for us.
                    Now, to make it an easy and stress-free experience, you can use our own social media tool below:
                  </p>
                  <ul className="procedure-list">
                    <li>Give us a shoutout on <span>LinkedIn</span> and <span>Twitter</span> by tagging <span>NeoITO </span> and <span>Faiz</span>.</li>
                    <li>Please use these tags so that we can get better visibility:
                      <p>#startups #entrepreneurship #founders #neoito #irecommendneoito #innovation</p></li>
                    <li>To make it easier, we've made post templates for you to directly copy and paste on each social media channel.
                        All you have to do is to make sure <span>Faiz</span> and <span>NeoITO</span> are tagged.</li>
                  </ul>
                  {/* <button type="button" className="social-media-review-btn" onClick={goToPostReviewPage}>Get Started Social Media Review Tool</button> */}
                </div>
              </div>
            </div>
            <div className="py-20 right-review-section relative flex justify-center">
              <PostPreview />
            </div>
          </section>
          )}
        </main>
        <Footer />
      </>
      )}
    </div>
  );
};

export default LandingPage;
