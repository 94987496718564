import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { getClientTemplates } from '../Review/Apis/ReviewApis';
import TemplatesTab from '../Review/Tabs/TemplatesTab';

const PostPreview = () => {
  const history = useHistory();
  const [templates, setTemplates] = useState<any[]>([]);
  const [finalTemplate, setFinalTemplate] = useState([]);
  const [currentTab, setCurrentTab] = useState('');
  const pathName = history.location.pathname.split('/');

  const handleTemplateChange = (templateContent: any) => {
    const tempData: any = finalTemplate.map((temp: any) => {
      if (temp.platformName === templateContent.platformName) {
        return templateContent;
      }
      return temp;
    });
    setFinalTemplate(tempData);
  };

  const tabChange = async (platformName: string) => {
    setCurrentTab(platformName);
  };

  const getData = async () => {
    const clientId = pathName[pathName.length - 1];
    getClientTemplates({ clientId }).then((data) => {
      console.log(data);

      if (data.templates) {
        localStorage.setItem('clientId', data.clientDetails.clientId);
        setTemplates(data.templates);
        setFinalTemplate(data.templates);
        const firstPlatform: any = data.templates[0];
        setCurrentTab(firstPlatform.platformName);
      }
    //   if (data.clientDetails) {
    //     setClientDetails(data.clientDetails);
    //   }
    //   setIsLoading(false);
    })
      .catch((err) => {
        console.error(err);
        // setIsLoading(false);
      });
  };
  const receiveVal = (val: any) => {
    console.log('val', val);
  };
  const handleCopyBtn = (e: any) => {
    e.preventDefault();
    const tabDetails = templates.filter((temp:any) => temp.platformName === currentTab);
    if (tabDetails) {
      navigator.clipboard.writeText(tabDetails[0].text ?? '');
      toast.success('Copied to clipboard');
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mx-4 text-left flex justify-start flex-col preview-section self-center">
        <div className="header py-4 px-6">
          <h3>Post Template</h3>
        </div>
        <div className="px-6 templates-tab-container">
          <TemplatesTab
            showSelectedLine={false}
            handleOnChangeTemplateText={handleTemplateChange}
            selectedPlatforms={templates?.map((template: any) => template.platformName)}
            templateData={templates}
            tabChange={tabChange}
            handleCopyAction={receiveVal}
          />
        </div>
        <button type="button" className="copy-template-btn p-3 mx-6 mt-2 mb-4" onClick={handleCopyBtn}>Copy to Clipboard</button>
      </div>
    </>
  );
};

export default PostPreview;
