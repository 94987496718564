import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// import Dashboard from '../../assets/images/svg/dashboard1.svg';
// import Review from '../../assets/images/svg/review.svg';
// import NavBarMenu from './Menu';
import logo from '../../assets/images/svg/logo1.svg';
import MenuIcon from '../../assets/images/svg/menu-icon.svg';
import './styles.scss';

const SideNavBar = () => {
  const history = useHistory();
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const pathname = history.location.pathname.split('/').slice(1);

  return (
    <aside className="relative bg-sidebar h-screen w-1/4 sm:block bx-shadow-right">
      <div className="sidebar-profile px-4 flex flex-wrap items-center py-2 w-full bx-shadow-btm">
        <button
          type="button"
          className="mobile-nav-toggle"
          onClick={() => setNavMenuOpen(!navMenuOpen)}
        >
          <img src={MenuIcon} alt="Menu" />
        </button>
        <NavLink to="#">
          <img src={logo} alt="Neoito" className="logo" />
        </NavLink>
      </div>
      <div className="side-nav h-82 flex flex-wrap justify-start menu-items p-4 items-center flex-col">
        <div className="w-full items-center menu-item mx-1 my-2 items-baseline">
          <NavLink to="/dashboard/d-1" activeClassName="activeClass" className="menu-card items-center overflow-hidden mx-auto flex px-4 py-3">
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" className="mr-4">
              <path d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z" />
            </svg>
            <h1 className="flex">Dashboard</h1>
          </NavLink>
        </div>
        <div className="w-full items-center menu-item mx-1 my-2">
          <NavLink to="/dashboard/new-review-link" activeClassName="activeClass" className="menu-card items-center overflow-hidden mx-auto flex px-4 py-3">
            <svg width="22" height="21" viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg" className="mr-4">
              <path d="M15.8 17L13 20.5L10.2 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16V5.103C4 4.83778 4.10536 4.58343 4.29289 4.39589C4.48043 4.20836 4.73478 4.103 5 4.103H21C21.2652 4.103 21.5196 4.20836 21.7071 4.39589C21.8946 4.58343 22 4.83778 22 5.103V16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17H15.8ZM1 0H18V2H2V13H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0Z" />
            </svg>
            <h1 className="flex">Create Review Link</h1>
          </NavLink>
        </div>
      </div>
      {/* {navMenuOpen && <div className="mobile-nav-overly" />} */}
      {/* {navMenuOpen && (
        <nav className="mobile-nav d-lg-none">
          <button
            type="button"
            className="close-active"
            onClick={() => setNavMenuOpen(!navMenuOpen)}
          >
            &times;
          </button>
          <ul>
            <li className={pathname[0] === 'dashboard' ? 'active' : ''}>
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className={pathname[0] === 'new-review-link' ? 'active' : ''}>
              <a href="/new-review-link">Create Review Link</a>
            </li>
          </ul>
        </nav>
      )} */}
      {/* <NavBarMenu /> */}
      <div className={`sidebar ${navMenuOpen === true ? 'active' : ''}`}>
        <div className="sd-header">
          <button className="btn btn-primary" type="button" onClick={() => setNavMenuOpen(!navMenuOpen)}>&times;</button>
        </div>
        <div className="sd-body">
          <ul>
            <li>
              <a href="/dashboard/d-1" className={`sd-link ${pathname[1] === 'd-1' ? 'active' : ''}`}>
                <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" className="mr-4">
                  <path d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z" />
                </svg>
                Dashboard
              </a>
            </li>
            <li>
              <a href="/dashboard/new-review-link" className={`sd-link ${pathname[1] === 'new-review-link' ? 'active' : ''}`}>
                <svg width="22" height="21" viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg" className="mr-4">
                  <path d="M15.8 17L13 20.5L10.2 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16V5.103C4 4.83778 4.10536 4.58343 4.29289 4.39589C4.48043 4.20836 4.73478 4.103 5 4.103H21C21.2652 4.103 21.5196 4.20836 21.7071 4.39589C21.8946 4.58343 22 4.83778 22 5.103V16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17H15.8ZM1 0H18V2H2V13H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0Z" />
                </svg>
                Create Review Link
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={`sidebar-overlay ${navMenuOpen === true ? 'active' : ''}`} />
    </aside>
  );
};

export default SideNavBar;
