/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// import Layout from '../pages/Layout/Layout';
// import Login from '../pages/Auth/Login/Login';
import authSlice from '../redux/slices/auth.slice';
import { setTopLevelNavigation } from './navigator';
import { RootState } from '../redux/store/rootReducer';
// import Register from '../pages/Auth/Register/Register';
// import VerifyEmail from '../pages/Auth/VerifyEmail/VerifyEmail';
// import Resetpassword from '../pages/Auth/Resetpassword/Resetpassword';
// import ForgotPassword from '../pages/Auth/Forgotpassword/Forgotpassword';
// import VerifyEmailSuccess from '../pages/Auth/VerifyEmailSuccess/VerifyEmailSuccess';
// import RestPasswordSuccess from '../pages/Auth/RestPasswordSuccess/RestPasswordSuccess';
import Review from '../pages/Review/Review';
import LandingPage from '../pages/LandingPage/LandingPage';
// import PostReview from '../pages/Post Review/PostReview';
import LinkedInPage from '../pages/Post Review/LinkedInPage';

export default function Routes() {
  const dispatch = useDispatch();
  const {
    initialAuthentcationRequest,
    // authenticated
  } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (!initialAuthentcationRequest) {
      dispatch(authSlice.actions.checkauth());
    }
  }, []);

  return (
    <Router ref={setTopLevelNavigation}>
      {/* {
        initialAuthentcationRequest === true && authenticated === false
          ? (
            <>
              <Route path="/login" render={() => <Login />} />
              <Route exact path="/register" render={() => <Register />} />
              <Route exact path="/verifyEmail" render={() => <VerifyEmail />} />
              <Route exact path="/verifyEmailSuccess" render={() => <VerifyEmailSuccess />} />
              <Route exact path="/forgotPassword" render={() => <ForgotPassword />} />
              <Route exact path="/resetpassword" render={() => <Resetpassword />} />
              <Route exact path="/restPasswordSuccess" render={() => <RestPasswordSuccess />} />
            </>
          )
          : null
      } */}
      {/* {
        initialAuthentcationRequest === true && authenticated === true
          ? <Route path="/dashboard" render={() => <Review />} />
          : null
      } */}
      {/* <Route exact path="/" render={() => <Login />} /> */}
      <Route path="/dashboard" render={() => <Review />} />
      <Route path="/" exact render={() => <Redirect to="/dashboard/new-review-link" />} />
      <Route exact path="/review-acknowledgement/:id" render={() => <LandingPage />} />
      {/* <Route exact path="/post-review/:id" render={() => <PostReview />} /> */}
      <Route exact path="/linkedIn/" render={() => <LinkedInPage />} />
    </Router>
  );
}
