import React, { useState, useEffect, useRef } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import 'rc-tabs/assets/index.css';
import '../../Post Review/styles.scss';
import Tick from '../../../assets/images/svg/tick(1).svg';

const defaultTabKey = '0';

const PanelContent = (props: any) => {
  const {
    template, onChangeEvent,
    showSelectedLine,
    handleSendCopyVal,
  } = props;

  const copiedDivRef: any = useRef<HTMLDivElement>(null);
  const [templateData, setTemplateData] = useState(template);
  const [templateContent, setTemplateContent] = useState(template.text);

  const onChange = (event: any) => {
    onChangeEvent(
      { ...template, text: event.currentTarget.textContent },
    );
  };

  const sendCopyData = () => {
    if (copiedDivRef !== null) {
      handleSendCopyVal(copiedDivRef);
    }
  };

  const findMentionsAndHashtags = (text: string) => {
    const contentText = text.split(' ').map((str) => {
      if (str.startsWith('@')) {
        if (str.includes('.')) {
          const strArray = str.split('.');
          return (
            <>
              <b key={str}>
                {strArray[0]}
              </b>
              {'. '}
              {strArray[1]}
              {' '}
            </>
          );
        }
        return (
          <b key={str}>
            {str}
            {' '}
          </b>
        );
      }
      if (str.startsWith('#')) {
        if (str.includes('.')) {
          const strArray = str.split('.');
          return (
            <>
              <b key={str}>
                {strArray[0]}
              </b>
              {'. '}
              {strArray[1]}
              {' '}
            </>
          );
        }
        return (
          <b key={str}>
            {str}
            {' '}
          </b>
        );
      }

      if (str.includes('#')) {
        const strArray = str.split('#');
        return (
          <>
            {strArray[0]}
            {' '}
            <b key={str}>
              {`#${strArray[1]}`}
            </b>
            {' '}
          </>
        );
      }
      if (str.includes('@')) {
        const strArray = str.split('@');
        return (
          <>
            {strArray[0]}
            {' '}
            <b key={str}>
              {`@${strArray[1]}`}
            </b>
            {' '}
          </>
        );
      }
      return `${str} `;
    });
    return contentText;
  };

  useEffect(() => {
    sendCopyData();
  }, [copiedDivRef !== null]);

  useEffect(() => {
    setTemplateData(template);
    // const defaultTagList = template.tags.map((tag: string) => tag);
    setTemplateContent(template?.text);
    // setUpdatedTemplateContent(template?.text);
    // setDefaultTags(defaultTagList);
  }, [template]);
  return (
    <>
      <div className={`p-2 outline-none w-full ${templateData.posted ? 'template-text-section disabled' : 'template-text-section'}`}>
        <div contentEditable={showSelectedLine} ref={copiedDivRef} className={showSelectedLine ? 'p-2' : 'py-2'} placeholder="Select your template from above options..." onInput={onChange} suppressContentEditableWarning>
          {showSelectedLine ? templateContent : findMentionsAndHashtags(templateContent)}
        </div>
      </div>
    </>
  );
};

const templateTitles = (newItem: any, showSelectedLine: boolean) => (
  <>
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={newItem.icon} />
    </svg>
    {!showSelectedLine && <span className="seperator" />}
    {newItem.displayName}
    {(!showSelectedLine && newItem.posted) && <img src={Tick} alt="selected" className="tick-img" />}
  </>
);

const TemplatesTab = (props: any) => {
  const {
    showSelectedLine,
    handleOnChangeTemplateText,
    selectedPlatforms, templateData,
    tabChange,
    // handleCopyAction,
  } = props;
  const [templates, setTemplates] = useState([]);
  const onTabClick = (key: any) => {
    if (tabChange) {
      const selectedTab: any = templates[key];
      tabChange(selectedTab.platformName);
    }
  };

  const onChange = (data: any) => {
    if (data) {
      handleOnChangeTemplateText(data);
    }
  };

  const handleCopyAction = () => {
  };

  useEffect(() => {
    setTemplates(templateData);
  }, [templateData]);
  return (
    <>
      {selectedPlatforms.length > 0 && (
        <Tabs
          defaultActiveKey={defaultTabKey}
          renderTabBar={() => (
            <ScrollableInkTabBar
              onTabClick={onTabClick}
              styles={{
                height: '6px',
                background: '#1877F2',
                borderRadius: '4px 4px 0px 0px',
              }}
            />
          )}
          renderTabContent={() => <TabContent animated={false} />}
          className="template-tabs"
        >
          {
            templates?.filter((template: any) => selectedPlatforms.includes(template.platformName))
              .map((template: any, i) => (
                <TabPane tab={templateTitles(template, showSelectedLine)} key={i}>
                  {showSelectedLine && (<div className="choose-template-lbl">Choose template</div>)}
                  <PanelContent
                    template={template}
                    showSelectedLine={showSelectedLine}
                    onChangeEvent={onChange}
                    handleSendCopyVal={handleCopyAction}
                  />
                </TabPane>

              ))
          }
        </Tabs>
      )}
    </>
  );
};

export default TemplatesTab;
