import { useEffect } from 'react';
import { setAuth } from '../Review/Apis/ReviewApis';

function LinkedInPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const channel = new BroadcastChannel('linkedIn-login');

  useEffect(() => {
    console.log(queryParams, code, state);
    if (code && state) {
      setAuth(code, state).then(() => closeWindow());
    }
  }, []);
  const closeWindow = async () => {
    channel.postMessage('jhhjhjhhjhjh');
    await window.localStorage.setItem('loggedIn', 'true');
    window.close();
  };
  return (null);
}

export default LinkedInPage;
