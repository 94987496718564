import axios from 'axios';
import { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET, REACT_APP_REDIRECT_URI } from '../../../utils/Constants';
import { baseUrl } from '../Constants/Constants';
import axiosInstance from './AxiosInstance';

const qs = require('querystring');

export const setAuth = (code: string, state: string) => {
  const clientId = localStorage.getItem('clientId');
  return (axios.post(`${baseUrl}/setLinkedInAuth`, { clientId, code, state }).then((response) => (response.data)));
};
export const getClientTemplates = (data: any) => (axios.get(`${baseUrl}/getClientTemplates`,
  { params: data }).then((response) => (response.data)));

export const getClient = (data: any) => (axios.get(`${baseUrl}/getClientDetails`,
  { params: data }).then((response) => (response.data))
);
export const getTags = () => (axios.get(`${baseUrl}/getAllTags`).then((response) => (response.data)));

export const getReviewById = (data: any) => (
  axiosInstance.get(data)
);

export const createReviewPostRequest = (data: any) => (axios.post(`${baseUrl}/addClientReviewTemplate`, data).then((response) => (response.data))
);
export const getNewReviewData = () => (axios.get(`${baseUrl}/newReview`)
  .then((response) => response.data));

export const getLinkedInAccessToken = (authToken: string) => {
  const parameters = {
    grant_type: 'authorization_code',
    code: authToken,
    redirect_uri: REACT_APP_REDIRECT_URI,
    client_id: REACT_APP_CLIENT_ID,
    client_secret: REACT_APP_CLIENT_SECRET,
  };
  return (axios({
    method: 'post',
    url: 'https://www.linkedin.com/oauth/v2/accessToken',
    data: qs.stringify(parameters),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
  }).then((result) => result.data)
  );
};

export const authenticateAccessToken = () => {
  const tokenStr = localStorage.getItem('access_token');
  const urlToAuthenticate = 'https://api.linkedin.com/v2/me';
  const headers = {
    Authorization: `Bearer ${tokenStr}`,
  };
  return (axios.get(urlToAuthenticate, { headers })
    .then((response) => (response.data))
    // .catch((err) => (console.log('err', err)))
  );
};

export const getProfileData = (reqData: any) => {
  const profileReq = reqData;
  return (axios.get(`${baseUrl}/postReview`, { params: profileReq, timeout: 5000 })
    .then((response) => response.data));
};
export const linkedInUserDetails = (data: any) => {
  console.log('data', data);

  return (axios.post(`${baseUrl}/linkedInData`, data, { timeout: 5000 })
    .then((response) => {
      console.log(response);
      return (response.data);
    })
  );
};
export const sharePost = (id: string, token: string, template: any, visibility: string) => (axios.post(`${baseUrl}/shareLinkedInPost`, {
  id, token, template, visibility,
}, { timeout: 5000 })
  .then((response) => {
    console.log('posted', response);
    return (response.data);
  })
);

export const linkedInToken = (data: any) => {
  // const tokenStr = localStorage.getItem('access_token');
  // const reqData = {
  //   author: `urn:li:person:${id}`,
  //   lifecycleState: 'PUBLISHED',
  //   specificContent: {
  //     'com.linkedin.ugc.ShareContent': {
  //       shareCommentary: {
  //         text: ,
  //       },
  //       shareMediaCategory: 'NONE',
  //     },
  //   },
  //   visibility: {
  //     'com.linkedin.ugc.MemberNetworkVisibility': 'CONNECTIONS',
  //   },
  // };

  // const shareReq = {
  //   access_token: tokenStr,
  //   post: reqData,
  // };
  console.log('post data', data);
  return (axios.post(`${baseUrl}/getToken`, data, { timeout: 5000 })
    .then((response) => {
      console.log(response);
      // eslint-disable-line no-debugger
      return (response.data);
    })
  );
};
