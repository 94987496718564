import React from 'react';
import { useMediaQuery } from 'react-responsive';
import logo from '../../assets/images/svg/neoito-logo.svg';
// import Facebook from '../../assets/images/svg/facebook(2).svg';
// import Twitter from '../../assets/images/svg/twitter(2).svg';
// import Linkedin from '../../assets/images/svg/linkedin(2).svg';
// import Instagram from '../../assets/images/svg/instagram(2).svg';
import './styles.scss';

const Footer = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 532px)' });
  return (
    <footer id="footer" className="flex flex-col pb-6 w-full px-6">
      <div className="container flex flex-col items-center p-6 m-6 footer-top-section self-center">
        <img src={logo} alt="logo" className="p-2 footer-logo" />
        <div className="flex p-2 mt-8 items-center">
          <a href="https://www.facebook.com/neoito" className="px-3 icon-facebook">
            <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 11.5H9.5L10.5 7.5H7V5.5C7 4.47 7 3.5 9 3.5H10.5V0.14C10.174 0.0970001 8.943 0 7.643 0C4.928 0 3 1.657 3 4.7V7.5H0V11.5H3V20H7V11.5Z" />
            </svg>
          </a>
          <a href="https://twitter.com/neoitocom" className="px-3 icon-twitter">
            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.1618 2.65593C20.3984 2.99362 19.5888 3.2154 18.7598 3.31393C19.6336 2.79136 20.2875 1.96894 20.5998 0.999927C19.7798 1.48793 18.8808 1.82993 17.9438 2.01493C17.3144 1.34151 16.4802 0.89489 15.5708 0.744511C14.6614 0.594133 13.7277 0.748418 12.9151 1.18338C12.1024 1.61834 11.4562 2.30961 11.0769 3.14972C10.6976 3.98983 10.6066 4.93171 10.8178 5.82893C9.15492 5.74558 7.52814 5.31345 6.0431 4.56059C4.55805 3.80773 3.24794 2.75097 2.19781 1.45893C1.8261 2.09738 1.63076 2.82315 1.63181 3.56193C1.63181 5.01193 2.36981 6.29293 3.49181 7.04293C2.82782 7.02202 2.17844 6.84271 1.59781 6.51993V6.57193C1.59801 7.53763 1.93218 8.47354 2.54366 9.22099C3.15513 9.96843 4.00629 10.4814 4.95281 10.6729C4.33642 10.84 3.69012 10.8646 3.06281 10.7449C3.32968 11.5762 3.84982 12.3031 4.5504 12.824C5.25099 13.3449 6.09694 13.6337 6.96981 13.6499C6.10229 14.3313 5.10899 14.8349 4.04669 15.1321C2.98439 15.4293 1.87394 15.5142 0.778809 15.3819C2.69051 16.6114 4.9159 17.264 7.18881 17.2619C14.8818 17.2619 19.0888 10.8889 19.0888 5.36193C19.0888 5.18193 19.0838 4.99993 19.0758 4.82193C19.8947 4.23009 20.6014 3.49695 21.1628 2.65693L21.1618 2.65593Z" />
            </svg>
          </a>
          <a href="https://www.linkedin.com/company/neoito/mycompany/" className="px-3 icon-linkedin">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.93994 2.00002C4.93968 2.53046 4.72871 3.03906 4.35345 3.41394C3.97819 3.78883 3.46937 3.99929 2.93894 3.99902C2.40851 3.99876 1.89991 3.78779 1.52502 3.41253C1.15014 3.03727 0.939676 2.52846 0.939942 1.99802C0.940207 1.46759 1.15117 0.958988 1.52644 0.584103C1.9017 0.209218 2.41051 -0.00124153 2.94094 -0.000976312C3.47137 -0.000711096 3.97998 0.210257 4.35486 0.585517C4.72975 0.960777 4.94021 1.46959 4.93994 2.00002ZM4.99994 5.48002H0.999942V18H4.99994V5.48002ZM11.3199 5.48002H7.33994V18H11.2799V11.43C11.2799 7.77002 16.0499 7.43002 16.0499 11.43V18H19.9999V10.07C19.9999 3.90002 12.9399 4.13002 11.2799 7.16002L11.3199 5.48002Z" />
            </svg>
          </a>
          <a href="https://www.instagram.com/neo.ito/" className="px-3 icon-instagram">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0C12.717 0 13.056 0.00999994 14.122 0.0599999C15.187 0.11 15.912 0.277 16.55 0.525C17.21 0.779 17.766 1.123 18.322 1.678C18.8305 2.1779 19.224 2.78259 19.475 3.45C19.722 4.087 19.89 4.813 19.94 5.878C19.987 6.944 20 7.283 20 10C20 12.717 19.99 13.056 19.94 14.122C19.89 15.187 19.722 15.912 19.475 16.55C19.2247 17.2178 18.8311 17.8226 18.322 18.322C17.822 18.8303 17.2173 19.2238 16.55 19.475C15.913 19.722 15.187 19.89 14.122 19.94C13.056 19.987 12.717 20 10 20C7.283 20 6.944 19.99 5.878 19.94C4.813 19.89 4.088 19.722 3.45 19.475C2.78233 19.2245 2.17753 18.8309 1.678 18.322C1.16941 17.8222 0.775931 17.2175 0.525 16.55C0.277 15.913 0.11 15.187 0.0599999 14.122C0.0129999 13.056 0 12.717 0 10C0 7.283 0.00999994 6.944 0.0599999 5.878C0.11 4.812 0.277 4.088 0.525 3.45C0.775236 2.78218 1.1688 2.17732 1.678 1.678C2.17767 1.16923 2.78243 0.775729 3.45 0.525C4.088 0.277 4.812 0.11 5.878 0.0599999C6.944 0.0129999 7.283 0 10 0ZM10 5C8.67392 5 7.40215 5.52678 6.46447 6.46447C5.52678 7.40215 5 8.67392 5 10C5 11.3261 5.52678 12.5979 6.46447 13.5355C7.40215 14.4732 8.67392 15 10 15C11.3261 15 12.5979 14.4732 13.5355 13.5355C14.4732 12.5979 15 11.3261 15 10C15 8.67392 14.4732 7.40215 13.5355 6.46447C12.5979 5.52678 11.3261 5 10 5ZM16.5 4.75C16.5 4.41848 16.3683 4.10054 16.1339 3.86612C15.8995 3.6317 15.5815 3.5 15.25 3.5C14.9185 3.5 14.6005 3.6317 14.3661 3.86612C14.1317 4.10054 14 4.41848 14 4.75C14 5.08152 14.1317 5.39946 14.3661 5.63388C14.6005 5.8683 14.9185 6 15.25 6C15.5815 6 15.8995 5.8683 16.1339 5.63388C16.3683 5.39946 16.5 5.08152 16.5 4.75ZM10 7C10.7956 7 11.5587 7.31607 12.1213 7.87868C12.6839 8.44129 13 9.20435 13 10C13 10.7956 12.6839 11.5587 12.1213 12.1213C11.5587 12.6839 10.7956 13 10 13C9.20435 13 8.44129 12.6839 7.87868 12.1213C7.31607 11.5587 7 10.7956 7 10C7 9.20435 7.31607 8.44129 7.87868 7.87868C8.44129 7.31607 9.20435 7 10 7Z" />
            </svg>
          </a>
        </div>
      </div>
      <div className="container flex justify-between mx-6 self-center text-base footer-bottom-section">
        <h6 className="text-white">Copyright © 2021</h6>
        <div className="text-white policies-section">
          {!isSmallScreen ? (
            <>
              <a href="https://www.neoito.com/privacy-policy">
                <span className="mx-3">Privacy Policy</span>
              </a>
              <a href="https://www.neoito.com/cookie-policy">
                <span className="mx-3">Cookie Policy</span>
              </a>
              <a href="https://www.neoito.com/sitemap">
                <span className="mx-3">Sitemap</span>
              </a>
              <a href="https://www.neoito.com/terms-of-use">
                <span className="mx-2">Terms of Use</span>
              </a>
            </>
          ) : (
            <>
              <a href="https://www.neoito.com/privacy-policy">
                <span className="mx-3">Privacy Policy</span>
              </a>
              <a href="https://www.neoito.com/sitemap">
                <span className="mx-3">Sitemap</span>
              </a>
              <a href="https://www.neoito.com/cookie-policy">
                <span className="mx-3">Cookie Policy</span>
              </a>
              <a href="https://www.neoito.com/terms-of-use">
                <span className="mx-2">Terms of Use</span>
              </a>
            </>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
