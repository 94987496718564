import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import NewReview from './NewReview';
import PreLoader from './PreLoader';
import SideNavBar from './SideNavBar';

const Review = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);
  return (
    <>
      <PreLoader loading={isLoading} />
      {!isLoading && (
        <section className="flex w-full bg-white review-interface">
          <SideNavBar />
          <div className="flex content-part md:w-3/4 flex flex-col h-screen overflow-y-hidden">
            <div className="bx-shadow-btm header-80" />
            <div className="content-part-h-82 px-4 pb-4 pt-2">
              <Switch>
                <Route exact path="/dashboard/d-1" />
                <Route exact path="/dashboard/new-review-link" component={NewReview} />
              </Switch>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default Review;
