/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import 'react-accessible-accordion/dist/fancy-example.css';
import TemplatesTab from './Tabs/TemplatesTab';
import help from '../../assets/images/png/Help.png';
import copy from '../../assets/images/svg/copy.svg';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { createReviewPostRequest, getNewReviewData } from './Apis/ReviewApis';
import { toast } from 'react-toastify';
import { rootUrl } from '../../utils/Constants';
import { reviewFormSchema } from './Validation/validation';
import { ClipLoader } from 'react-spinners';

let options: { value: string, label: string }[] = [];

const customStyles = {
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: '100'
  }),

  control: (provided: any) => ({
    ...provided,
    border: '1px solid #ACACAC',
    borderRadius: '6px',
  }),

  valueContainer: (provided: any) => ({
    ...provided,
    color: '#FF6E43'
  }),

  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#524C4B',
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  multiValue: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#FFF3F0',
    borderRadius: '12px',
    padding: '0 2px',
  }),

  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#FF6E43',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#524C4B',
    ':hover': {
      color: '#524C4B',
    },
  }),

}
const LinkButton = (props: any) => {
  const { isButtonLoading } = props;
  return (<button type="submit" className="create-link-btn px-3 py-2 mt-4 mb-5 ml-2 mr-4">
    {isButtonLoading ? (
      <ClipLoader
        css={`
          display: block;
          margin: 0 auto;
          border-color: '#0E278F';
                  `}
        size={15}
        color="#0E278F"
        loading
      />
    ) :
      'Create Review Link'}
  </button>);
}

const NewReview = () => {

  const [template, setTemplate] = useState<any[]>([]);
  const [finalTemplate, setFinalTemplate] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [copyLinkVal, setCopyLinkVal] = useState('');
  const [copied, setCopied] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const copiedInputRef: any = useRef<HTMLInputElement>(null);
  const handleSelectedOptions = (item: any) => {
    setSelectedOptions(item);
  }

  const selectedTemplateContent = (data: any) => {
    const tempData: any = finalTemplate.filter(
      (temp: any) => temp.platformName !== data.platformName,
    );
    tempData.push(data);
    setFinalTemplate(tempData);
  }

  const saveSelectedReviewTemplate = async (value: any) => {
    console.log('values', value);

    setIsButtonLoading(true);
    const selectedTemplatesArray = selectedOptions.map((item) => item.value)
    let templatePayload: any[] = [];
    for (const platform of selectedTemplatesArray) {
      const platformData: any[] = finalTemplate.filter((item: any) => item.platformName === platform);
      if (platformData.length) {
        const tag1 = await platformData[0].text.match(/@[a-z0-9]+/gi);
        const tag2 = await platformData[0].text.match(/#[a-z0-9]+/gi);
        templatePayload.push({ text: platformData[0].text, templateId: platformData[0].templateId, platformId: platformData[0].platformId, tags: [...(tag1 ? tag1 : []), ...(tag2 ? tag2 : [])] });
      }
    }
    const req = {
      clientName: value.clientName,
      projectName: value.projectName,
      templates: templatePayload,
    }
    createReviewPostRequest(req).then((responseData) => {
      if (responseData) {
        const id = responseData.clientId;
        setIsButtonLoading(false);
        setCopyLinkVal(`${rootUrl}review-acknowledgement/${id}`);
        toast.success('Link generated successfully', { autoClose: 5000 });
        setCopied(false)
      }
    })
      .catch((err) => {
        console.log('Err', err);
        setIsButtonLoading(false);
        // toast.error
      })
  }

  const handleCopyBtn = (e: any) => {
    e.preventDefault();
    if (copiedInputRef !== null) {
      copiedInputRef.current.select();
      e.target.focus();
      navigator.clipboard.writeText(copiedInputRef.current.value)
      setCopied(true);
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  const changeTemplateOrder = async (items: any) => {
    const tabValues = items.map((item: any) => item.value);
    template.sort((a, b) => {
      const firstElement = a.platformName;
      const secondElement = b.platformName;
      if (tabValues.indexOf(firstElement) > tabValues.indexOf(secondElement)) {
        return 1;
      }
      else {
        return -1;
      }

    });
  }

  useEffect(() => {
    const a = localStorage.getItem('clientId');
    console.log('localStorage', a);
    options = [];
    getNewReviewData().then((data) => {
      if (data) {
        setTemplate(data);
        setFinalTemplate(data);
        data.forEach((template: any) => {
          options.push({ value: template.platformName, label: template.displayName },)
        });
      }

    });
  }, []);
  return (
    <main className="w-full h-full flex-grow px-3">
      <div className="pt-4 pb-4 add-review-title">
        <div className="pt-6 pb-4 px-4 flex items-center w-full text-26px justify-between title">Review Link Creation</div>
        <div className="w-full divider" />
      </div>
      <div className="h-full w-full flex flex-col mb-lg-log bg-white px-3 pt-3 pb-5 form-container">
        <Formik
          initialValues={{ 'clientName': '', 'projectName': '', platformValue: [] }}
          validationSchema={reviewFormSchema}
          onSubmit={(values, actions) => {
            console.log('values', values, actions);
            saveSelectedReviewTemplate(values);
          }}
        >
          {({ handleBlur, handleChange, handleSubmit, errors, touched }) => (
            <Form className="w-full" onSubmit={handleSubmit}>
              <div className="form-group py-2 px-2">
                <label htmlFor="clientName" className={`py-1 ${errors.clientName && touched.clientName ? 'lbl-error' : 'lbl-name'}`}>
                  Client Name
                </label><br />
                <Field
                  id="clientName"
                  type="text"
                  name="clientName"
                  placeholder="Enter client name"
                  className={`form-control my-1 px-3 project-name-input ${errors.clientName && touched.clientName ? "text-input error" : "text-input"}`}
                  onKeyDown={handleKeyDown}
                />
                <ErrorMessage name="clientName" render={msg => <div className="input-feedback">{msg}</div>} />
              </div>
              <div className="form-group py-2 px-2">
                <label htmlFor="projectName" className={`py-1 ${errors.projectName && touched.projectName ? 'lbl-error' : 'lbl-name'}`}>
                  Project Name
                </label><br />
                <Field
                  id="projectName"
                  type="text"
                  name="projectName"
                  placeholder="Enter project name"
                  className={`form-control my-1 px-3 project-name-input ${errors.projectName && touched.projectName ? "text-input error" : "text-input"}`}
                  onKeyDown={handleKeyDown}
                />
                <ErrorMessage name="projectName" render={msg => <div className="input-feedback">{msg}</div>} />
              </div>
              <div className="form-group py-2 px-2">
                <div className="flex justify-between items-center mb-1">
                  <label className="py-1 lbl-name">
                    Sharing Platforms
                  </label>
                  <div className="ml-4 flex items-center cursor-pointer tooltip-1">
                    <img src={help} />
                    <span className="tooltiptext text-sm flex px-2 py-3 rounded-4">
                      Choose a template from given options to generate a link...!
                    </span>
                  </div>
                </div>
                <Select className={`mb-2 ${errors.platformValue && touched.platformValue ? "template-options error" : "template-options"}`}
                  name="platformValue"
                  id="platformValue"
                  placeholder="Select the platforms of your choice "
                  value={selectedOptions}
                  options={options}
                  isMulti
                  styles={customStyles}
                  onBlur={() => {
                    handleBlur({ target: { name: 'platformValue' } });
                  }}
                  onChange={item => {
                    let event = { target: { name: 'platformValue', value: item } }
                    handleSelectedOptions(item);
                    changeTemplateOrder(item);
                    handleChange(event)
                  }}
                  // onKeyDown={handleKeyDown}
                  isClearable={false} />
                <TemplatesTab showSelectedLine={true} selectedPlatforms={selectedOptions.map(platform => platform.value)}
                  handleOnChangeTemplateText={selectedTemplateContent} templateData={template} />
              </div>
              {selectedOptions.length ? <LinkButton isButtonLoading={isButtonLoading} /> : null}

            </Form>
          )}
        </Formik>
        {copyLinkVal ? <div className="flex copy-link-section">
          <input
            type="text"
            size={10}
            ref={copiedInputRef}
            className="h-10 border rounded-lg outline-none px-4 ml-2 mr-4"
            value={copyLinkVal}
          />
          <button className="copy-link-btn p-2" onClick={handleCopyBtn} disabled={copied}>
            <img src={copy} alt="" />
            {copied ? 'Copied' : 'Copy Link'}</button>
        </div> : null}
      </div>
    </main>
  );
};

export default NewReview;
