import axios from 'axios';
// import * as queryString from 'querystring';

import { baseUrl } from '../Constants/Constants';

const axiosInstance = axios.create({
  baseURL: baseUrl,
});
axios.defaults.timeout = 2500;

const requestHandler = (request: any) => {
  const { data } = request;
  if (data) {
    request.data = { ...data };
  }
  return request;
};

axiosInstance.interceptors.request.use((request) => (requestHandler(request)));

const errorHandler = (error: any) => (
  Promise.reject({ ...error })
);

const successHandler = (response: any) => (response);

axiosInstance.interceptors.response.use(
  (response) => (successHandler(response)),
  (error) => (errorHandler(error)),
);

export default axiosInstance;
